import React from 'react';
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import ReactDOM from 'react-dom';
import App from './containers/App/';
import rootReducer from './reducers'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import 'intersection-observer';

const store = createStore(rootReducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

setTimeout(() => 
 ReactDOM.render(
	<Provider store={store}>
		<Router>
	  		<React.StrictMode>
	   			<App />
	  		</React.StrictMode>
	  	</Router>	
  	</Provider>,
 document.getElementById('root')
), 2800);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

