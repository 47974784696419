import React from 'react';
import Square from '../images/square';
import { useInView } from 'react-intersection-observer';

import { 
	Section,
	BlueSquare,
	Header,
	Title,
	ContainerSquare,
	ContainerAnimation,
	Text,
	Count,
	YellowCircle,
	GreenTriangle,
	PurpleSquare,
	ContainerSlide,
	Anchor,
	AnimatedCardContent,
	AnimatedContainerCard
 } from './styles';

 // import Swiper core and required components
import SwiperCore, { Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import './swiper.css';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

// install Swiper components
SwiperCore.use([Navigation]);


function HowWeDo(props) {
	const { ref, inView, entry } = useInView({threshold: 0.5,triggerOnce: true});

	return(
		<Section>
			<Anchor id="how" />
			<Header>
				<BlueSquare/>
				<Title>Here’s how we do it:</Title>
			</Header>
			<ContainerSquare>
				<Square />
			</ContainerSquare>	
			<ContainerAnimation ref={ref}>
				<YellowCircle />
				<GreenTriangle />
				<PurpleSquare/>
				<Swiper
				      loop={true}
				      navigation
			    >	
					<SwiperSlide>
						<ContainerSlide>
							<Text>We use Facebook to log you in and create a profile</Text>
							<Count >1</Count>
							<AnimatedContainerCard open={inView}>
								<AnimatedCardContent open={inView}>
									<img src="./images/registration.png" />
								</AnimatedCardContent>
							</AnimatedContainerCard>
						</ContainerSlide>	
			        </SwiperSlide>
			        <SwiperSlide>
			        	<ContainerSlide>
				        	<Text>Crowder will then briefly ask about your interests and use your location to find people around</Text>
							<Count >2</Count>
							<AnimatedContainerCard open>
								<AnimatedCardContent open>
									<img src="./images/interests.png" />
								</AnimatedCardContent>
							</AnimatedContainerCard>
						</ContainerSlide>	
			        </SwiperSlide>
			        <SwiperSlide>
			        	<ContainerSlide>
				        	<Text>When found, we’ll show who is nearby and matches your search</Text>
							<Count >3</Count>
							<AnimatedContainerCard open>
								<AnimatedCardContent open>
									<img src="./images/searching.png" />
								</AnimatedCardContent>
							</AnimatedContainerCard>
						</ContainerSlide>	
			        </SwiperSlide>
			        <SwiperSlide>
			        	<ContainerSlide>
				        	<Text>Now, just find people that share your interests, follow them and create new friendships and relationships!</Text>
							<Count>4</Count>
							<AnimatedContainerCard open>
								<AnimatedCardContent open>
									<img src="./images/profile.png" />
								</AnimatedCardContent>
							</AnimatedContainerCard>
						</ContainerSlide>	
			        </SwiperSlide>
			    </Swiper>
			</ContainerAnimation>
		</Section>
	);
}

export default HowWeDo;
export {MobileScrollFixer} from './styles';