import React,{useState,useEffect, useRef,} from 'react';
import PropTypes from 'prop-types';
import {validateEmail} from '../../utils';

import {
    ContainerForm,
    InputWrapper,
    Input,
    ErrorText,
    SubmitButton
 } from './styles';

const EmailForm = ({subscribe, status, message ,style,white}) => {
	const [email,setEmail] = useState('');
    const [statusState,setStatus] = useState({status,message});
    const inputRef = useRef();
    const buttonRef = useRef();

	useEffect(()=>{
		setStatus({status ,message})
    },[status,message])
    
    const isError = () => {
        return statusState.status === "error";
    }

    const handleClear = (e) => {
        if(isError()) {
            e.preventDefault();
            setEmail('');
            setStatus({status: '',message:''});
            if(inputRef.current) {
                inputRef.current.focus();
            }
        }
        if(buttonRef.current) {
            buttonRef.current.blur();
        }
    }
	return (
		<ContainerForm white={white}> 
			<div style={style}>
                {statusState.status !== 'success' ? 
                    (
                        <form onSubmit={(e)=>{
                            e.preventDefault();
                            if(email === '') {
                                setStatus({status: 'error',message:'Email can\'t be empty'});
                            }
                            else if(!validateEmail(email)) {
                                setStatus({status: 'error',message:'wrong email'});
                            } else {
                                subscribe({EMAIL: email});
                            }
                        }}> 
                            <InputWrapper white={white}>
                                <Input white={white} ref={inputRef} error={statusState.status === "error"} value={email} onChange={(e)=>{
                                    setStatus({status: '',message:''});
                                    setEmail(e.target.value);
                                }}/>
                                {isError() && <ErrorText white={white}>{statusState.message}</ErrorText>}
                            </InputWrapper>
                            <SubmitButton white={white} ref={buttonRef}  type={isError() ? 'button' : 'submit'} onClick={handleClear} error={isError()}>Submit</SubmitButton>
                        </form>
                        
                    ) : <p style={{ color: "green",lineHeight: "56px" }}>{statusState.message}</p>
                }
			</div> 	
		</ContainerForm>
	);
}

EmailForm.propTypes = {
    subscribe: PropTypes.func,
    status: PropTypes.string,
    message: PropTypes.string,
    style: PropTypes.shape(),
    white: PropTypes.bool
}

export default EmailForm;