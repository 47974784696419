import React from "react";

const Logo = () => {
    return (      
		<svg width="144" height="34" viewBox="0 0 144 34" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.5093 18.2999C8.35374 17.1392 7.69892 15.5723 7.69892 13.9281C7.69892 12.2838 8.33448 10.7169 9.5093 9.5562C10.6649 8.39552 12.2249 7.73781 13.8619 7.73781C15.499 7.73781 17.059 8.37618 18.2145 9.5562C19.3701 10.7169 20.0249 12.2838 20.0249 13.9281C20.0249 15.05 19.736 16.1333 19.1775 17.0812L24.7049 22.6331C29.0575 17.1586 28.7301 9.16931 23.6842 4.10104C20.9686 1.35412 17.4056 0 13.8619 0C10.3182 0 6.75522 1.35412 4.05891 4.08169C-1.35297 9.51751 -1.35297 18.3386 4.05891 23.7744L13.8619 33.6208L19.3123 28.1463L9.5093 18.2999Z" fill="white"/>
			<path d="M41.1858 19.4367C41.1858 21.5601 41.8783 23.3483 43.291 24.7732C44.7037 26.1981 46.5596 26.8966 48.8587 26.8966C51.1023 26.8966 53.346 26.0025 54.6756 24.5776L53.3183 22.063C52.0718 23.2644 50.6868 23.8791 49.1634 23.8791C47.8615 23.8791 46.7812 23.46 45.9779 22.5939C45.1746 21.7277 44.7591 20.666 44.7591 19.4088C44.7591 18.1515 45.1469 17.1177 45.9502 16.2795C46.7535 15.4134 47.7784 14.9943 49.0526 14.9943C50.4652 14.9943 51.684 15.4972 52.7089 16.531L54.2878 14.1002C52.9859 12.6753 50.8807 11.9768 48.8587 11.9768C46.5596 11.9768 44.7037 12.7032 43.291 14.1561C41.8783 15.581 41.1858 17.3412 41.1858 19.4367Z" fill="white"/>
			<path d="M59.9068 26.5613V20.8057C59.9068 19.3529 60.2669 18.1235 60.9594 17.1177C61.6519 16.1119 62.7045 15.6089 64.0618 15.6089C64.4496 15.6089 64.7266 15.6369 64.9205 15.6648V12.1724C64.6158 12.1444 64.3942 12.1165 64.2557 12.1165C62.1782 12.1165 60.4331 13.7091 59.7683 15.8045H59.7129L59.7683 14.8266V12.3121H56.3889V26.5613H59.9068Z" fill="white"/>
			<path d="M73.1125 11.9768C70.9519 11.9768 69.1237 12.6753 67.6279 14.1002C66.1321 15.4972 65.3842 17.2853 65.3842 19.4367C65.3842 21.588 66.1321 23.3762 67.6279 24.8011C69.1237 26.1981 70.9519 26.8966 73.1402 26.8966C75.3285 26.8966 77.1567 26.1981 78.6525 24.8011C80.1483 23.3762 80.8961 21.588 80.8961 19.4367C80.8961 17.2853 80.1483 15.4972 78.6248 14.1002C77.129 12.6753 75.3008 11.9768 73.1125 11.9768ZM68.9575 19.4367C68.9575 18.1515 69.373 17.0898 70.1763 16.2516C70.9796 15.4134 71.9768 14.9943 73.1402 14.9943C74.3036 14.9943 75.3008 15.4134 76.1041 16.2516C76.9351 17.0898 77.3506 18.1515 77.3506 19.4367C77.3506 20.7499 76.9351 21.8116 76.1318 22.6498C75.3285 23.46 74.3313 23.8791 73.1402 23.8791C71.9491 23.8791 70.9519 23.46 70.1486 22.6498C69.3453 21.8116 68.9575 20.7499 68.9575 19.4367Z" fill="white"/>
			<path d="M89.5398 26.5613L91.9497 18.4309C92.0882 17.9279 92.199 17.425 92.2821 16.8942H92.3375L92.6976 18.4309L95.0798 26.5613H98.9855L103.279 12.3121H99.6226L97.1573 21.6719C97.0188 22.1748 96.908 22.6777 96.8526 23.1806H96.7972L96.4925 21.6719L93.8887 12.34H90.7586L88.1548 21.6719C88.0163 22.1748 87.9055 22.6777 87.8224 23.1806H87.767L87.49 21.6719L85.0247 12.3121H81.3129L85.6064 26.5613H89.5398Z" fill="white"/>
			<path d="M103.786 19.4367C103.786 21.6439 104.367 23.46 105.503 24.8291C106.639 26.1981 108.162 26.8966 110.046 26.8966C112.012 26.8966 113.647 25.9187 114.505 24.4379H114.561L114.505 25.332V26.5613H117.802V6.72412H114.311V13.0106C114.311 13.3179 114.339 13.5973 114.367 13.8487H114.311C113.425 12.5915 112.04 11.9768 110.184 11.9768C108.301 11.9768 106.749 12.6753 105.558 14.0443C104.367 15.4134 103.786 17.2015 103.786 19.4367ZM107.331 19.4367C107.331 16.7265 108.882 15.0222 110.877 15.0222C112.012 15.0222 112.871 15.4693 113.48 16.3354C114.09 17.2015 114.395 18.2073 114.395 19.4088C114.395 22.3983 112.843 23.907 110.849 23.907C108.744 23.907 107.331 22.1189 107.331 19.4367Z" fill="white"/>
			<path d="M119.914 19.4367C119.914 21.5322 120.606 23.2924 122.019 24.7452C123.432 26.1702 125.26 26.8966 127.531 26.8966C129.609 26.8966 131.797 26.1143 133.21 24.857L131.825 22.3145C130.523 23.3483 129.193 23.8791 127.781 23.8791C125.537 23.8791 123.764 22.4821 123.543 20.1072H133.265C133.348 19.5205 133.376 19.0455 133.376 18.7382C133.376 16.7824 132.822 15.1619 131.714 13.9046C130.606 12.6194 129.083 11.9768 127.116 11.9768C124.983 11.9768 123.266 12.6753 121.908 14.0723C120.579 15.4413 119.914 17.2295 119.914 19.4367ZM123.626 17.6765C123.958 15.8604 125.26 14.659 127.033 14.659C128.667 14.659 129.831 15.8045 129.858 17.6765H123.626Z" fill="white"/>
			<path d="M138.987 26.5613V20.8057C138.987 19.3529 139.347 18.1235 140.039 17.1177C140.732 16.1119 141.784 15.6089 143.142 15.6089C143.529 15.6089 143.806 15.6369 144 15.6648V12.1724C143.696 12.1444 143.474 12.1165 143.336 12.1165C141.258 12.1165 139.513 13.7091 138.848 15.8045H138.793L138.848 14.8266V12.3121H135.469V26.5613H138.987Z" fill="white"/>
		</svg>
    );
};

export default Logo;
