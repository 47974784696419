import styled from 'styled-components';
import {f_c_c, device } from '../styles';


export const RoundWrapper = styled.button`
    ${f_c_c};
    width: 128px;
    height: 128px;
    background: #C1B7E9;
    border-radius: 50%;
    border: none;
    -webkit-tap-highlight-color: transparent;

    @media ${device.tillLaptop} {
        width: 96px;
        height: 96px;
    }
    ${({active}) => active ? `
        transition: transform 500ms;
        transform: scale(0.6);
    ` : ``}
`