import styled from "styled-components";
import { color, f_c_sb, f_sb, device } from '../styles';

export const ContainerFooter = styled.footer`
	padding: 64px 152px 32px;
	background: ${color.black};
	position: relative;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	color: ${color.white};

	& ul {
		list-style: none;
	}

   	@media ${device.tillLaptop} {
   		padding: 64px 60px 32px;
	}  

	@media ${device.tillTablet} {
   		padding: 34px 16px 32px;
   		font-size: 16px;
		line-height: 28px;
	}  
`;

export const ContainerFlex = styled.div`
	${f_sb};
	align-items: flex-start;

	@media ${device.tillLaptop} {
   		flex-direction: column;
	}  

`;

export const ContainerInfo = styled.div`
	${f_c_sb};
	width: 60%;

	& p {
		margin-bottom: 16px;
	}

	& a {
		display: block;
		margin-bottom: 8px;
		text-decoration: none;
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		color: ${color.white};
		opacity: 0.6;
	}

	@media ${device.tillLaptop} {
   		width: 100%;
	} 

	@media ${device.tillTablet} {
		flex-direction: column;
		align-items: flex-start;

		& > div {
			margin-top: 0;
			margin-bottom: 16px;
			width: 100%;
		}

		& > div > div {
			position: relative;
			width: 100%;
		}

		& > div > div > svg {
			position: absolute;
			right: 0;
    		top: 0;
		}


		& a {
	   		font-size: 16px;
			line-height: 28px;
		]	
	}  
`;

export const ContainerContacts = styled.div`
	& div {
		margin-bottom: 32px;
	}

	& p {
		margin-bottom: 16px;
	}

	& a {
		display: block;
		text-decoration: none;
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		color: ${color.white};
		opacity: 0.6;
	}

	@media ${device.tillLaptop} {
   		${f_sb};
   		width: 80%;

	   	& div {
	   		margin-top: 32px;
	   		margin-bottom: unset;
	   		margin-right: 52px;
		}
	} 

	@media ${device.tillTablet} {
		flex-direction: column;
		width: 100%;
		align-items: flex-start;

		& div {
	   		margin-top: 0;
	   		margin-bottom: 32px;
		}

		& a {
	   		font-size: 16px;
			line-height: 28px;
		]	
	}  
`;

export const Remark = styled.div`
	margin-top: 48px;
	opacity: 0.3;
	text-align: center;
`;
