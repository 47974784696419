import styled, { keyframes,css } from "styled-components";
import { color, f_c_sb, f_c_c, device } from '../styles';

const AppearAnimation = keyframes`
    0% { opacity: 0; }
  100% { opacity: 1; }
`;

const AppearFacesAnimation = keyframes`
    0% { opacity: 0; }
  100% { opacity: 0.3; }
`;

const ContainerPulse = keyframes`
    0% { transform: scale(1) }
  50% { transform: scale(1.05) }
  100% { transform: scale(1) }
`;

const ButtonPulse = keyframes`
    0% { transform: scale(0.97) }
  50% { transform: scale(1.03) }
  100% { transform: scale(0.97) }
`;

const BgPulse = keyframes`
    0% { transform: scale(1) translate(-50%, -50%); opacity: 0};
    15% { transform: scale(1) translate(-50%, -50%); opacity: 0.05};
  	100% { transform: translate(-50%,-50%) scale(4); opacity: 0;};
`;

const ScaleImage1 = keyframes`
    0% { transform: scale(0) };
    20% { transform: scale(0) };
    40% { transform: scale(1) };
  100% { transform: scale(1) };
`;

const ScaleImage2 = keyframes`
    0% { transform: scale(0) };
    30% { transform: scale(0) };
    50% { transform: scale(1) };
  100% { transform: scale(1) };
`;

const ScaleImage3 = keyframes`
    0% { transform: scale(0) };
    40% { transform: scale(0) };
    60% { transform: scale(1) };
  100% { transform: scale(1) };
`;

const ScaleImage4 = keyframes`
    0% { transform: scale(0) };
    50% { transform: scale(0) };
    70% { transform: scale(1) };
  100% { transform: scale(1) };
`;

const ScaleImage5 = keyframes`
    0% { transform: scale(0) };
    60% { transform: scale(0) };
    80% { transform: scale(1) };
  100% { transform: scale(1) };
`;

const ScaleImage6 = keyframes`
    0% { transform: scale(0) };
    70% { transform: scale(0) };
    90% { transform: scale(1) };
  100% { transform: scale(1) };
`;

const ScaleImage7 = keyframes`
    0% { transform: scale(0) };
    80% { transform: scale(0) };
  100% { transform: scale(1) };
`;

const PhoneAppear = keyframes`
    0% { transform: translate(-50%, -50%) scale(0)};
    80% { transform: translate(-50%, -50%) scale(0)};
  100% { transform: translate(-50%, -50%) scale(1)};
`;

const CrowdingToScreen = keyframes`
    0% { transform: scale(1)};
    80% { transform: scale(1)};
  100% { transform: scale(0.32)};
`;

const DropDown = keyframes`
    0% { transform: translateY(0)};
    85% { transform: translateY(0)};
  100% { transform: translateY(480px)};
`;

export const Section = styled.section`
	overflow: hidden;
	padding: ${({fullHeight}) => fullHeight ? 0 : '144px 0 0'};
	height: ${({fullHeight}) => fullHeight ? '100vh' : 'auto'};
	background-color: ${color.purple};
	${f_c_c};
	flex-direction: column;

	& ul {
    	list-style: none;
    }
`;

export const ContainerAnimation = styled.div`
	${f_c_c};
	${({isButton}) => !isButton && 'position: relative;'}
	flex-direction: column;
	height: calc(100vh - 144px);
	min-height: 800px;
	padding: 0 96px;
	background: ${color.purple};
    width: 100%;

    @media ${device.tillLaptop} {
		padding: 0 48px;
		${({notCards}) => notCards && 'padding-top: 144px;'}
	}
`;

export const ButtonSkip = styled.button`
	position: absolute;
	right: 15%;
	${({isButton}) => isButton ? 'top: 85vh;' : 'bottom: 15%;'}
    border: none;
    background-color: transparent;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 38px;
	color: #D6CFF0;

	@media ${device.tillLaptop} {
		font-size: 20px;
		line-height: 34px;
	}
`;

export const ContainerButtonAnimation = styled.div`
	${f_c_c};
	margin-top: -144px;
	position: relative;
	height: 90%;

	& > p {
		position: absolute;
		right: -70%;
	    top: 50%;
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 38px;
		color: #D6CFF0;

		@media ${device.tillLaptop} {
			font-size: 20px;
			line-height: 34px;
		}
	}
`;

export const ContainerButton = styled.div`
	${f_c_c};
	position: relative;
	width: 320px;
	height: 320px;
	background: #F7F5FF;
	border-radius: 50%;
	animation: ${AppearAnimation} 2s ease-in-out, ${ContainerPulse} 4s ease-in-out infinite;
	transition: .3s;
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	text-align: center;
	color: #FFFFFF;

	@media ${device.tillLaptop} {
		width: 240px;
		height: 240px;
	}

	&:hover{
		opacity: 0.7;
	}

	& > button {
		${f_c_c};
		width: 234px;
		height: 234px;
		background: #9887DA;
		box-shadow: 0px 8.95105px 20.1399px rgba(152, 135, 218, 0.5);
		border-radius: 50%;
		border: none;
		animation: ${ButtonPulse} 4s ease-in-out infinite;
		transition: .3s;

		&:active {
			opacity: 0.5;
		}

		@media ${device.tillLaptop} {
			width: 192px;
			height: 192px;
		}
	}
`;

export const DarkPurpleContainer1 = styled.div`
	z-index: 0;
	width: 320px;
	height: 320px;
	position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%) scale(0.1);
    background: #14263C;
	opacity: 0;
	border-radius: 50%;
	animation: ${BgPulse} 4s ease-in-out 2;
	animation-delay: 0.5s;

	@media ${device.tillLaptop} {
		width: 240px;
		height: 240px;
	}
`;

export const DarkPurpleContainer2 = styled.div`
	z-index: 0;
	width: 320px;
	height: 320px;
	position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%) scale(0.1);
    background: #14263C;
	opacity: 0;
	border-radius: 50%;
	animation: ${BgPulse} 4s ease-in-out 2;
	animation-delay: 1.5s;

	@media ${device.tillLaptop} {
		width: 240px;
		height: 240px;
	}
`;

export const DarkPurpleContainer3 = styled.div`
	z-index: 0;
	width: 320px;
	height: 320px;
	position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%) scale(0.1);
    background: #14263C;
	opacity: 0;
	border-radius: 50%;
	animation: ${BgPulse} 4s ease-in-out 2;
	animation-delay: 2.5s;

	@media ${device.tillLaptop} {
		width: 240px;
		height: 240px;
	}
`;

export const ContainerCrowding = styled.div`
	${(props) => !props.static && css`animation: ${AppearAnimation} 2s ease-in-out, ${ContainerPulse} 4s ease-in-out infinite;`}
	${f_c_c};
	position: relative;
	width: 320px;
	height: 320px;
	background: #F7F5FF;
	border-radius: 50%;
	transition: .3s;

	@media ${device.tillLaptop} {
		width: 240px;
		height: 240px;
	}

	& > div {
		${f_c_c};
		flex-direction: column;
		width: 234px;
		height: 234px;
		background: #9887DA;
		box-shadow: 0px 8.95105px 20.1399px rgba(152, 135, 218, 0.5);
		border-radius: 50%;
		border: none;
		${(props) => !props.static && css`animation: ${ButtonPulse} 4s ease-in-out infinite;`}
		transition: .3s;

		@media ${device.tillLaptop} {
			width: 192px;
			height: 192px;
		}
	}

	& p {
		margin-top: 16px;
		font-family: 'Roboto', sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 21px;
		text-align: center;
		color: #FFFFFF;
		max-width: 136px;
	}
`;

export const ContainerPhone = styled.div`
	${(props) => !props.static && css`animation: ${PhoneAppear} 4.5s ease-in-out;`}
	z-index: 10;
	position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    ${f_c_c};

    & img {
    	@media ${device.tillLaptop} {
			width: 100%;
    		margin: 0 auto;
		}
    }
`;

export const ContainerCrowdingAnimation = styled.div`
	${(props) => !props.static && css`animation: ${CrowdingToScreen} 4.5s ease-in-out;`}
	transform: scale(0.32);
	position: relative;
    z-index: 888;
    width: 100%;
    height: 100%;
    max-width: 976px;
    min-height: 984px;
    max-height: 1120px;
	${f_c_c};

	& > ul > li {
		position: absolute;

		@media ${device.tillLaptop} {
			margin-left: 2%;
	    	margin-right: -8%;
		}

		&:first-child {
			top: 8%;
			left: 27%;
			${(props) => !props.static && css`animation: ${ScaleImage6} 3s ease-in-out;`}
		}

		&:nth-child(2){
			top: 14%;
			left: 37%;
			${(props) => !props.static && css`animation: ${ScaleImage3} 3s ease-in-out;`}   
		}

		&:nth-child(3){
			top: 19%;
    		left: 53%;
			${(props) => !props.static && css`animation: ${ScaleImage2} 3s ease-in-out;`}
		}

		&:nth-child(4){
			right: 22%;
    		top: 12%;
			${(props) => !props.static && css`animation: ${ScaleImage1} 3s ease-in-out;`}
		}

		&:nth-child(5){
			top: 24%;
    		right: 19%;
			${(props) => !props.static && css`animation: ${ScaleImage4} 3s ease-in-out;`}
		}

		&:nth-child(6){
			top: 40%;
    		right: 16%;
			${(props) => !props.static && css`animation: ${ScaleImage5} 3s ease-in-out;`}
		}

		&:nth-child(7){
			bottom: 34%;
    		right: 14%;
			${(props) => !props.static && css`animation: ${ScaleImage2} 3s ease-in-out;`}
		}

		&:nth-child(8){
			bottom: 27%;
   			right: 24%;
			${(props) => !props.static && css`animation: ${ScaleImage3} 3s ease-in-out;`}
		}

		&:nth-child(9){
			bottom: 17%;
    		right: 33%;
			${(props) => !props.static && css`animation: ${ScaleImage6} 3s ease-in-out;`}
		}

		&:nth-child(10){
			bottom: 6%;
    		right: 39%;
			${(props) => !props.static && css`animation: ${ScaleImage1} 3s ease-in-out;`}
		}

		&:nth-child(11){
			bottom: 13%;
    		left: 36%;
			${(props) => !props.static && css`animation: ${ScaleImage4} 3s ease-in-out;`}
		}

		&:nth-child(12){
			bottom: 21%;
    		left: 20%;
			${(props) => !props.static && css`animation: ${ScaleImage7} 3s ease-in-out;`}
		}

		&:nth-child(13){
			bottom: 32%;
    		left: 13%;
			${(props) => !props.static && css`animation: ${ScaleImage1} 3s ease-in-out;`}
		}

		&:nth-child(14){
			bottom: 39%;
    		left: 21%;
			${(props) => !props.static && css`animation: ${ScaleImage4} 3s ease-in-out;`}
		}

		&:nth-child(15){
			bottom: 51%;
    		left: 15%;
			${(props) => !props.static && css`animation: ${ScaleImage7} 3s ease-in-out;`}
		}

		&:nth-child(16){
			left: 13%;
    		top: 20%;
			${(props) => !props.static && css`animation: ${ScaleImage1} 3s ease-in-out;`}
		}

		&:last-child {
			left: 24%;
    		top: 22%;
			${(props) => !props.static && css`animation: ${ScaleImage5} 3s ease-in-out;`}
		}
	}

	& img {
    	@media ${device.tillLaptop} {
			width: 60%;
		}
    }

    @media ${device.tillLaptop} {
		height: 75%;
		min-height: 700px;
	}
	@media (min-width: 800px) and (max-width: 1024px){
		width: 654px;
		height:700px
	}
`;


export const ContainerMainAnimation = styled.div`
	margin-top: -144px;
	transform: translateY(480px);
	${(props) => !props.static && css`animation: ${DropDown} 5.5s ease-in-out;`}
	width: 100%;
	height: 100%;
	position: relative;
    max-width: 1150px;
	${f_c_c};
`;


export const ContainerAnimationBottom = styled.div`
	background: linear-gradient(180deg, #9887DA 0%, #FFFFFF 100%);
    height: 600px;
	width: 100%;

`;

export const HeroBottom = styled.div`
	background: linear-gradient(180deg, #9887DA 0%, #FFFFFF 100%);
    height: 450px;
    width: 110%;

    @media ${device.tillLaptop} {
		width: 100%;
	}
`;

export const MobilePhone = styled.img`
	width:136px;
	border-radius: 20px;
`;

export const ContainerBg = styled.div`
	${f_c_c};
	background: no-repeat center center url("./images/mobile_bg_phone.png");
    height: 450px;
    width: 100%;
    background-size: contain;

    & img {
    	max-width: 136px;
    	width: 30%;
    }
`;


export const ContainerFacesList = styled.div`
	animation: ${AppearFacesAnimation} 2s ease-in-out;
	width: 110%;
    height: 120%;
    position: absolute;
    opacity: 0.3;

	& > ul > li {
		position: absolute;

		@media ${device.tillLaptop} {
			margin-left: 2%;
	    	margin-right: -8%;
		}

		&:first-child {
			top: 6%;
    		left: 26%;
		}

		&:nth-child(2){
			left: 19%;
    		bottom: 23%;
		}

		&:nth-child(3){
			top: 14%;
    		left: 15%;
		}

		&:nth-child(4){
			right: 25%;
    		top: 11%;
		}

		&:nth-child(5){
			top: 23%;
    		right: 23%;
		}

		&:nth-child(6){
			top: 37%;
    		right: 19%;
		}

		&:nth-child(7){
			bottom: 28%;
    		right: 20%;
		}

		&:nth-child(8){
			bottom: 40%;
    		right: 25%;
		}

		&:nth-child(9){
			bottom: 15%;
    		right: 25%;
		}

		&:nth-child(10){
			bottom: 4%;
    		right: 39%;
		}

		&:nth-child(11){
			bottom: 3%;
    		left: 38%;
		}

		&:nth-child(12){
			bottom: 10%;
    		left: 24%;
		}

		&:nth-child(13){
			left: 39%;
    		top: 10%;
		}

		&:nth-child(14){
			bottom: 38%;
    		left: 27%;
		}

		&:nth-child(15){
			bottom: 52%;
    		left: 16%;
		}

		&:nth-child(16){
			left: 50%;
    		top: 9%;
		}

		&:last-child {
			left: 26%;
    		top: 25%;
		}

		@media (min-width: 800px) and (max-width: 1024px) {
			&:first-child {
				top: 12%;
				left: 75%;
			}
	
			&:nth-child(2){
				left: 64%;
				bottom: 8%;
			}
	
			&:nth-child(3){
				top: 16%;
				left: 8%;
			}
	
			&:nth-child(4){
				right: 33%;
				top: 6%;
			}
	
			&:nth-child(5){
				top: 24%;
				right: 16%;
			}
	
			&:nth-child(6){
				top: 37%;
				right: 9%;
			}
	
			&:nth-child(7){
				bottom: 33%;
				right: 11%;
			}
	
			&:nth-child(8){
				bottom: 44%;
				right: 21%;
			}
	
			&:nth-child(9){
				bottom: 3%;
				   right: 51%;
			}
	
			&:nth-child(10){
				bottom: 25%;
				right: 20%;
			}
	
			&:nth-child(11){
				bottom: 11%;
				left: 15%;
			}
	
			&:nth-child(12){
				bottom: 36%;
				left: 8%;
			}
	
			&:nth-child(13){
				left: 6%;
				top: 67%;
			}
	
			&:nth-child(14){
				top: 40%;
				left: 0%;
			}
	
			&:nth-child(15){
				bottom: 60%;
				left: 11%;
			}
	
			&:nth-child(16){
				left: 43%;
				top: 6%;
			}
	
			&:last-child {
				left: 22%;
				top: 8%;
			}
		}
	}

	& img {
		width: 90%;

    	@media ${device.tillLaptop} {
			width: 70%;
		}
    }

    @media ${device.tillLaptop} {
		width: 150%;
    	height: 110%;
	}

	@media (min-width: 800px) and (max-width: 1024px){
		width: 800px;
		max-height: 1000px;
	}
`;
