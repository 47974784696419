import React from "react";

const Square = () => {
    return (      	
		<svg width="1129" height="1711" viewBox="0 0 1129 1711" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect opacity="0.1" x="-26" y="346.699" width="1440" height="1440" rx="120" transform="rotate(-15 -26 346.699)" fill="#9887DA"/>
		</svg>
    );
};

export default Square;
