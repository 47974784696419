import styled, { keyframes ,css} from "styled-components";
import { color, f_c_sb, f_c_c, device } from '../../styles';

const AppearTitle = keyframes`
    0% { transform: translateX(-170%)};
  100% { transform: translateX(0)};
`;

const DisppearAnimation = keyframes`
    0% { opacity: 1; }
  100% { opacity: 0; }
`;

const AppearAnimation = keyframes`
    0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const Container = styled.div`
	z-index: 899;
	position: absolute;
	top: 144px;
	${(props) => !props.static && css`animation: ${AppearTitle} 1.5s ease-in-out;`}

	@media ${device.tillTablet} {
		position: relative;
		top: unset;
		animation: unset;
	}
`;

export const ContainerForm = styled.div`
	& > div {
		position: relative;
		display: flex;
		margin: 36px 0 48px;
		justify-content: center;
		animation: ${props =>
		    (props.showEmail) 
		        ? AppearAnimation
		        : "none"} .3s ease-in-out;
	}	

	& > div > div {
		position: absolute;
	    z-index: 999;
	    padding: 8px;
	    height: 68px;
        width: 364px;
	    color: #ffffff !important;
	    background-color: #9887da;
	    font-family: 'Gilroy',sans-serif;
	    font-style: normal;
	   	font-weight: 600;
		font-size: 20px;
		line-height: 32px;
	    top: -10px;
	    text-align: center;

	    @media ${device.tillTablet} {
			font-size: 16px;
    		line-height: 28px;
		}
	}        

    & input {
    	width: 272px;
		border: 2px solid #FFFFFF;
		box-sizing: border-box;
		border-radius: 8px;
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 28px;
		text-align: center;
		color: rgba(250, 250, 250, 0.7);
		background: ${color.purple};

		&::placeholder {
			color: rgba(250, 250, 250, 0.7);
		}

		@media ${device.tillTablet} {
			width: 190px;
		}
    }    

    & button {
    	background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 8H15' stroke='%239887DA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 1L15 8L8 15' stroke='%239887DA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    	background-color: #ffffff;
	    background-repeat: no-repeat;
	    background-position: center;
    	margin-left: 16px;
    	${f_c_c};
		border-radius: 8px;
		border: 0;
		font-size: 0;
		text-align: center;
		color: ${color.purple};
		width: 72px;
		height: 56px;
		transform: translate(-10px, -6px);
		transition: .3s;

		&::after {
			content: "";
		    width: 68px;
		    height: 52px;
		    border: 2px solid #FFFFFF;
		    border-radius: 8px;
		    position: absolute;
		    transform: translate(8px, 6px);
		    transition: .3s;
		}	

		&:hover {
			transform: translate(0, 0);

			&::after {
			    transform: translate(0, 0);
			}	
		}

		&:active {
			transform: translate(0, 0);
			background-color: #EFEBFF;


			&::after {
			     display: none;
			}	
		}
    }    
`;


export const ContainerTitle = styled.div`
	${f_c_c};
	flex-direction: column;

	@media ${device.tillTablet} {
		padding: 0 16px;
	}
`;

export const Title = styled.h1`
	text-align: center;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 104px;
	line-height: 168px;
	color: ${color.white};
	position: relative;

	&::after {
		content: '';
		position: absolute;
		width: 96px;
		height: 4px;
		background: #FFD600;
		bottom: -16px;
	    left: 50%;
	    margin-right: -50%;
	    transform: translate(-50%, 0);
	}

	@media ${device.tillLaptop} {
		font-size: 80px;
		line-height: 128px;
	}

	@media ${device.tillTablet} {
		font-size: 56px;
		line-height: 90px;
	}
`;

export const Description = styled.p`
	text-align: center;
	margin-top: 48px;
	max-width: 560px;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	color: ${color.white};

	@media ${device.tillTablet} {
		font-size: 16px;
		line-height: 28px;
	}
`;

export const ContainerButtonJoin = styled.div`
	width: 272px;
    margin: 40px auto;
	border: 2px solid #FFFFFF;
	box-sizing: border-box;
	border-radius: 10px;
	animation: ${props =>
        (props.animateButton) 
            ? DisppearAnimation
            : "none"} .3s ease-in-out;

`;

export const ButtonJoin = styled.button`
	background: #FFFFFF;
	border-radius: 8px;
	border: 0;
	padding: 14px 120px;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	color: ${color.purple};
	transform: translate(-10px, -6px);
	transition: .3s;

	&:hover {
		transform: translate(0, 0);
	}

	&:active {
		transform: translate(0, 0);
		background: #EFEBFF;
	}
`;

export const ContainerButtonAccept = styled.div`
	margin-left: 24px;
	border: 2px solid #FFFFFF;
	box-sizing: border-box;
	border-radius: 10px;
`;

export const FormJoin = styled.form`
	display: flex;
	margin: 40px 0;
	justify-content: center;

	animation: ${props =>
        (props.showEmail) 
            ? AppearAnimation
            : "none"} .3s ease-in-out;
`;

export const InputEmail = styled.input`
	width: 272px;
	border: 2px solid #FFFFFF;
	box-sizing: border-box;
	border-radius: 8px;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	color: rgba(250, 250, 250, 0.7);
	background: ${color.purple};

	&::placeholder {
		color: rgba(250, 250, 250, 0.7);
	}

	@media ${device.tillTablet} {
		width: 190px;
	}
`;

export const ButtonAccept = styled.button`
	${f_c_c};
	background: #FFFFFF;
	border-radius: 8px;
	border: 0;
	padding: 21px 30px;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	color: ${color.purple};
	transform: translate(-10px, -6px);
	transition: .3s;

	&:hover {
		transform: translate(0, 0);
	}

	&:active {
		transform: translate(0, 0);
		background: #EFEBFF;
	}
`;
