import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux'
import Header from '../../components/Header';
import Hero from '../../components/Hero';
import WhyCreated from '../../components/WhyCreated';
import HowWeDo,{MobileScrollFixer} from '../../components/HowWeDo';
import Join from '../../components/Join';
import Footer from '../../components/Footer';

import { LandingBody } from '../../components/styles';

import { showHeroContent } from '../../actions';



function App(props) {


	const { general, dispatch } = props;
	const { showHero } = general;

	const [ isMobile, setIsMobile ] = useState(false);
	const [ isResizing, setResize ] = useState(false);



//Get screen size to switch between Mobile and Web view	
	useLayoutEffect(() => {
	    function updateSize() {
		  setResize(true);	
	      if (window.innerWidth <= 767) {
	        setIsMobile(true);
	        dispatch(showHeroContent())
	      } else {
			if(!showHero){
				dispatch(showHeroContent())
			}  
	       	setIsMobile(false);
		  }
	    }  

		if (window.innerWidth <= 767) {
	        setIsMobile(true);
	        dispatch(showHeroContent())
	      } else {
	       	setIsMobile(false);
	    }
	    window.addEventListener("resize", updateSize);
	    return () => window.removeEventListener("resize", updateSize);
  	}, []);

 	
	return (
		<LandingBody>
			{ showHero ? <Header isResizing={isResizing} isMobile={isMobile}/> : null }
			<Hero isResizing={isResizing} isMobile={isMobile} general={general} dispatch={dispatch}/>
			{showHero ? (
				<React.Fragment>
					<WhyCreated />
					<MobileScrollFixer>
						<HowWeDo />
						<Join />
						<Footer isMobile={isMobile}/>
					</MobileScrollFixer>
				</React.Fragment>
			) : null}
		</LandingBody>    
	);
}
const mapStateToProps = state => ({
	general: state.general,
	dispatch: state.dispatch,

});

export default connect(mapStateToProps)(App);
