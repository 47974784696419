import styled, { keyframes,css } from "styled-components";
import { color, f_c_sb, f_c_c, device } from '../../styles';

export const AniamatedCards = styled.div`
	${f_c_c};
	height: 100%;
	position: relative;
	width: 100%;

	& > div {
		margin: 0 48px;
		width: 384px;
		height: 558px;
	}

	@media (max-height: 800px) {
		height: auto;
	}
`;

const moveAnimatin = (deg) => keyframes`
	0% {
		opacity: 1;
	}
	80% {
		transform: rotate(${deg}deg);
		opacity: 1;
	}
	100% {
		transform: rotate(${deg}deg);
		opacity: 0;
		display: none;
	}
`;

export const CardFirst = styled.div`
	position: absolute;
	padding: 8px;
	width: 384px;
	height: 558px;
    background: #FFFFFF;
	border-radius: 8px;
	text-align: center;
	z-index: 2;

	& > div {
		width: 368px;
		height: 424px;
		background: no-repeat center center url("./images/card1.png");
	}

	& > h3 {
		margin-top: 24px;
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 32px;
		line-height: 52px;
		color: #000000;
	}

	& > p {
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 26px;
		color: #000000;
		opacity: 0.5;
	}
	${({move}) => {
		if(move == 'right') {
			const transformOrigin = 'transform-origin: bottom;';
			return css`
				transform-origin: bottom;
				animation: ${moveAnimatin(45)} 800ms forwards;
			`
		} else if(move == 'left'){
			return css`
				transform-origin: bottom;
				animation: ${moveAnimatin(-45)} 800ms forwards;
			`
		} else {
			return ``;
		}
	}}
`;

export const CardSecond = styled.div`
	position: absolute;
	padding: 8px;
	width: 384px;
	height: 558px;
    background: #FFFFFF;
	border-radius: 8px;
	text-align: center;
	z-index: 2;
	& > div {
		width: 368px;
		height: 424px;
		background: no-repeat center center url("./images/card2.png");
	}

	& > h3 {
		margin-top: 24px;
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 32px;
		line-height: 52px;
		color: #000000;
	}

	& > p {
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 26px;
		color: #000000;
		opacity: 0.5;
	}
	${({move}) => {
		if(move == 'right') {
			const transformOrigin = 'transform-origin: bottom;';
			return css`
				transform-origin: bottom;
				animation: ${moveAnimatin(45)} 800ms forwards;
			`
		} else if(move == 'left'){
			return css`
				transform-origin: bottom;
				animation: ${moveAnimatin(-45)} 800ms forwards;
			`
		} else {
			return ``;
		}
	}}
`;

export const ContainerSwipe = styled.div`
	margin-top: 80px;

	@media (max-height: 800px) {
		margin-top: 2%
	}
`;
