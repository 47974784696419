import React, { Fragment, useState } from 'react';
import Collapse from "@kunukn/react-collapse";

import { 
	ContainerFooter,
	ContainerFlex,
	ContainerInfo,
	ContainerContacts,
	Remark
 } from './styles';

function Footer(props) {

	const { isMobile } = props;
	const [ isOpenJoin, setIsOpenJoin ] = useState(false);
	const [ isOpenSocials, setIsOpenSocials ] = useState(false);
	const [isOpenLegal, setIsOpenLegal] = useState(false);
	const currentYear = new Date().getFullYear();


	return(
		<ContainerFooter>
		{isMobile ? 
			<ContainerFlex>
				<ContainerInfo>
					<div onClick={() => setIsOpenJoin(!isOpenJoin)}>
						<div>
							<p>Join the Crowd</p>
							{isOpenJoin ?
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							:
							
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M12 5V19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>	
							}
						</div>	
						<Collapse isOpen={isOpenJoin}>
							<ul>
								<li><a href="https://apps.apple.com/ua/app/crowder/id1542450105 " target="_blank">iOS</a></li>
								<li><a href="https://play.google.com/store/apps/details?id=com.stcrowder.app&pcampaignid=web_share " target="_blank">Android</a></li>
							</ul>
						</Collapse>	
					</div>
					<div onClick={() => setIsOpenSocials(!isOpenSocials)}>
						<div>
							<p>Socials</p>
							{isOpenSocials ?
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							:
							
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M12 5V19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>	
							}
						</div>	
						<Collapse isOpen={isOpenSocials}>
							<ul>
								<li><a href="https://www.instagram.com/crowderapp/" target="_blank">Instagram</a></li>
								<li><a href="https://www.fb.me/crowderapp" target="_blank">Facebook</a></li>
							</ul>
						</Collapse>		
					</div>	
					<div onClick={() => setIsOpenLegal(!isOpenLegal)}>
						<div>
							<p>Legal</p>
							{isOpenLegal ?
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
							:
							
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M12 5V19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M5 12H19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>	
							}
						</div>	
						<Collapse isOpen={isOpenLegal}>
							<ul>
								<li><a href="/terms.html" target="_blank">Terms and Conditions</a></li>
								<li><a href="/privacy.html" target="_blank">Privacy Policy</a></li>
							</ul>
						</Collapse>		
					</div>		
				</ContainerInfo>
				<ContainerContacts>
					<div>
						<p>Need help?</p>
						<a href="mailto:support@appcrowder.com" target="_blank">support@appcrowder.com</a>
					</div>
					<div>
						<p>Press and partnership</p>
						<a href="mailto:email@appcrowder.com" target="_blank">email@appcrowder.com</a>
					</div>			
				</ContainerContacts>
			</ContainerFlex>	
			: 
			<ContainerFlex>
				<ContainerInfo>
					<div>
						<p>Join the Crowd</p>
						<ul>
							<li><a href="https://apps.apple.com/ua/app/crowder/id1542450105 " target="_blank">iOS</a></li>
							<li><a href="https://play.google.com/store/apps/details?id=com.stcrowder.app&pcampaignid=web_share " target="_blank">Android</a></li>
						</ul>
					</div>
					<div>
						<p>Socials</p>
						<ul>
							<li><a href="https://www.instagram.com/crowderapp/" target="_blank">Instagram</a></li>
							<li><a href="https://www.fb.me/crowderapp" target="_blank">Facebook</a></li>
						</ul>
					</div>	
					<div>
						<p>Legal</p>
						<ul>
							<li><a href="/terms.html" target="_blank">Terms and Conditions</a></li>
							<li><a href="/privacy.html" target="_blank">Privacy Policy</a></li>
						</ul>
					</div>		
				</ContainerInfo>
				<ContainerContacts>
					<div>
						<p>Need help?</p>
						<a href="mailto:support@appcrowder.com" target="_blank">support@appcrowder.com</a>
					</div>
					<div>
						<p>Press and partnership</p>
						<a href="mailto:email@appcrowder.com" target="_blank">email@appcrowder.com</a>
					</div>			
				</ContainerContacts>
			</ContainerFlex>	
		}
		<Remark>All rights reserved. Crowder Inc {currentYear}</Remark>
		</ContainerFooter>
	);
}

export default Footer;