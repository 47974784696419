import styled, {keyframes,css} from "styled-components";
import { color, f_c_sb, f_c_c, device } from '../styles';

const AppearHeader = keyframes`
    0% { transform: translateX(100%)};
  100% { transform: translateX(0)};
`;

export const ContainerHeader = styled.div`
   	${f_c_sb};
   	background-color: ${color.purple};
   	padding: 32px 152px;
   	position: fixed;
   	top: 0;
   	width: 100%;
	z-index: 999;
	${(props) => !props.static && css`animation: ${AppearHeader} 1s ease-in-out;`}


   	@media ${device.tillLaptop} {
	    padding: 24px 16px;
	}  

	@media ${device.tillTablet} {
	   animation: unset; 
	}  
`;

export const ContainerLogo = styled.div`
   z-index: 999;
`;

export const ContainerNav = styled.div`
   & > ul {
   		list-style: none;
	   	display: flex;
	   	font-family: "Gilroy", sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 32px;
		text-align: center;
		color: #FFFFFF;
   }

   & > ul > li > a {
		text-decoration: none;
	   	font-family: "Gilroy", sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 38px;
		text-align: center;
		color: #FFFFFF;

		&:active {
			color: #FFFFFF;
		}

		&:hover {
			outline: none;
		}
   	}

   & > ul > li{
   		margin-left: 40px;

	   	&:first-child {
	   		margin-left: 0;
	   	}
   }
`;

export const ContainerMobileNav = styled.div`
	@media ${device.tillLaptop} {
		${f_c_c};
		transform: ${props => props.isMenuOpen ? "translateX(0)" :  "translateX(100%)"}; 
		background-color: ${color.purple};
	    width: 100%;
	    height: 100%;
	    position: fixed;
	    left: 0;
	    top: 0;	
    	box-sizing: border-box;
    	transition: all 0.3s ease;
	} 

	& ul {
		list-style: none;
	}

	& ul > li {
		text-align: center;
	}


	& > ul > li > a {
		text-decoration: none;
	   	font-family: "Gilroy", sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 38px;
		text-align: center;
		color: #FFFFFF;

		&:active {
			color: #FFFFFF;
		}

		&:hover {
			outline: none;
		}
   	}

   & > ul > li{
   		margin-bottom: 24px;

	   	&:last-child {
	   		margin-bottom: 0;
	   	}
   }
`;	

export const HeaderBurgerButton = styled.button`
	border: none;
	background-color: transparent;
	z-index: 999;
`;

