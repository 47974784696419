import styled, { keyframes } from "styled-components";
import { color, f_c_sb, f_c_c, device } from '../styles';

const Rotation = keyframes`
	0% { 
	 	transform: rotate(0);
	}
  	100% { 
	 	transform: rotate(-360deg);
  	}
`;

const CadrAnimation = keyframes`
	0% { 
	 	transform: translate(0px, 0px);
	}
  	10% { 
	 	transform: translate(-16px, -16px);
  	}
  	80% { 
	 	transform: translate(-16px, -16px);
	 	opacity: 1;
  	}
  	100% { 
	 	transform: rotate(-18deg);
	 	opacity: 0;
  	}
`;

export const Section = styled.section`
	background-color: ${color.white};
	padding: 76px 72px 160px;

	@media ${device.tillLaptop} {
		padding: 76px 48px 160px;
	}

	@media ${device.tillTablet} {
		padding: 40px 16px 20px;
	}
`;

export const Anchor = styled.div`
	position: absolute;
	top: -50px;

	@media ${device.tillTablet} {
		top: -100px;
	}
`;

export const Header = styled.div`
	${f_c_c};
	flex-direction: column;
	position: relative;
	margin: 0 auto;

	@media ${device.tillLaptop} {
		max-width: 648px;
		padding: 0 16px;
	}
`;

export const BlueSquare = styled.div`
	width: 24px;
	height: 24px;
	background: #7EB3FF;
	transform: rotate(-25deg);
	position: absolute;
    right: 68px;
    top: 60%;

	@media ${device.tillLaptop} {
		right: -4%;
    	top: 83%;
	    width: 16px;
		height: 16px;
    }	

    @media ${device.tillTablet} {
		right: 0%;
    	top: 14%;
	}
`;

export const Title = styled.h2`
	text-align: center;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 80px;
	line-height: 128px;
	color: ${color.black};
	position: relative;

	@media ${device.tillLaptop} {
		font-size: 64px;
		line-height: 104px;
	}

	@media ${device.tillTablet} {
		font-size: 40px;
		line-height: 64px;
	}
`;

export const ContainerSquare = styled.div`
	position: absolute;
	right: 0;
    top: 0%;
`;

export const ContainerAnimation = styled.div`
	${f_c_c};
	margin-top: 80px;
	position: relative;
`;

export const Text = styled.div`
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	text-align: center;
	color: ${color.black};
	opacity: 0.6;
	max-width: 348px;

	@media ${device.tillLaptop} {
		max-width: 200px;
	}

	@media ${device.tillTablet} {
		font-size: 16px;
		line-height: 28px;
	}
`;

//animation: ${props =>
        // (props.animateAll) 
        //     ? Rotation
        //     : "none"} 3s ease-in-out infinite;

export const Count = styled.div`
	animation: ${Rotation} .5s ease-in-out;
	${f_c_c};
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 38px;
	color: ${color.white};
	width: 92px;
	height: 92px;
	background: #9887DA;
	border-radius: 16px;

	@media ${device.tillLaptop} {
		width: 48px;
		height: 48px;
		border-radius: 8px;
		font-size: 16px;
		line-height: 19px;
	}


	@media ${device.tillTablet} {
		margin: 24px 0;
	}
`;


export const ContainerSlide = styled.div`
	${f_c_sb};
	padding: 16px 56px;
	max-width: 1200px;
	margin: 0 auto;

	@media ${device.tillTablet} {
    	flex-direction: column-reverse;
	}
`;

export const ContainerCard = styled.div`
	background: #9887DA;
	border-radius: 32px;
	max-width: 348px;

	@media ${device.tillLaptop} {
		width: 188px;
		border-radius: 16px;
	}

	@media ${device.tillTablet} {
		border-radius: 16px;
	}
`;
//	animation: ${CadrAnimation} 3s ease-in-out infinite;

export const CardContent = styled.div`
	transform: translate(-16px, -16px);

	@media ${device.tillTablet} {
		transform: translate(-8px,-8px);
	}

	& img {
		width: 348px;
		height: 618px;
		border-radius: 32px;

		@media ${device.tillLaptop} {
			width: 188px;
			height: 344.38px;
			border-radius: 16px;
	}
`;



export const AnimatedContainerCard = styled(ContainerCard)`
	width: 348px;
	border-radius: 32px;

	@media ${device.tillLaptop} {
		width: 188px;
		border-radius: 16px;
	}

	${({open}) => open ? `
		transform: translate(0px,0px);
		padding-bottom: 13px;
		@media ${device.tillLaptop} {
			padding-bottom: 7px;
		}
		@media ${device.tillTablet} {
			padding-bottom: 0;
		}
	` : `
		transform: translate(-10px,-10px);
	`}
	transition: transform .5s ease-out;
`;

export const AnimatedCardContent = styled(CardContent)`
	& img {
		width: 348px;
		height: 618px;
		border-radius: 32px;

		@media ${device.tillLaptop} {
			width: 188px;
			height: 344.38px;
			border-radius: 16px;
		}	
	}
	${({open}) => open ? `
		transform: translate(-28px, -16px);

		@media ${device.tillLaptop} {
			transform: translate(-22px, -15px);
		}

		@media ${device.tillTablet} {
			transform: translate(-16px, -15px);
		}
	` : `
		transform: translate(0px,0px);
	`}
	transition: transform .5s ease-out;
`;

export const YellowCircle = styled.div`
	position: absolute;
	left: 72px;
    top: 200px;
	width: 24px;
	height: 24px;
	background: #FED876;
	border-radius: 50%;

	@media ${device.tillLaptop} {
		width: 16px;
		height: 16px;
		top: 100px;
	}
`;

export const GreenTriangle = styled.div`
	position: absolute;
	top: 15%;
    left: 52%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 16px 27.7px 16px;
	border-color: transparent transparent #45D09E transparent;
	transform: rotate(30deg);

	@media ${device.tillLaptop} {
    	border-width: 0 12px 20px 12px;
	}
`;

export const PurpleSquare = styled.div`
	position: absolute;
	bottom: 40px;
    left: 42%;
	width: 24px;
	height: 24px;
	background: #9887DA;
	transform: rotate(-10deg);

	@media ${device.tillLaptop} {    
    	width: 16px;
		height: 16px;
		bottom: -15px;
    	left: unset;
    	right: 30%;
	}
`;

export const MobileScrollFixer = styled.div`
	position: relative;
	overflow: hidden;
`;
