import React, { Fragment, useState } from 'react';

import Message from '../images/message';
import Hands from '../images/hands';
import Holiday from '../images/holiday';
import Triangle from '../images/triangle';

import { 
	Section,
	BlueCircle,
	PurpleSquare,
	Header,
	Title,
	Description,
	Content,
	ContainerTriangle,
	YellowTriangle,
	GreenSquare,
	PurpleCircle,
	BlueTriangle,
	YellowSquare,
	PurpleTriangle,
	GreenCircle,
	Anchor,
	Text,
	ContentBlock,
	ImageBlock
 } from './styles';

function WhyCreated(props) {

	return(
		<Section>
			<Anchor id="about"/>
			<PurpleSquare />
			<Header>
				<BlueCircle/>
				<Title>Why was Crowder created?</Title>
				<Description>Taking the first step to interact with someone can be very hard. <br/> Meeting people in new environments like schools, universities and cities is hard too! <br /> Crowder is here to change that.</Description>
			</Header>
			<ContainerTriangle>
				<Triangle />
			</ContainerTriangle>	
			<Content>	
				<ImageBlock>
					<img src="./images/why1.png" alt="person image" />
				</ImageBlock>
				<ContentBlock>
					<YellowTriangle />
					<Message/>
					<Text>We're here to help you break the ice and make your first interactions easier</Text>
					<GreenSquare />
				</ContentBlock>
				<ContentBlock>
					<PurpleCircle/>
					<Hands/>
					<Text>We're here to close the gap between you and other people by making the search for new friends or partners a lot easier</Text>
					<BlueTriangle/>
				</ContentBlock>
				<ImageBlock><img src="./images/why2.png" alt="person image" /></ImageBlock>
				<ImageBlock><img src="./images/why3.png" alt="person image" /></ImageBlock>
				<ContentBlock>
					<YellowSquare />
					<Holiday/>
					<Text>We’re here for you to have fun and explore</Text>
					<PurpleTriangle />
				</ContentBlock>
			</Content>
			<GreenCircle />
		</Section>
	);
}

export default WhyCreated;