import styled, { keyframes } from "styled-components";
import { color, f_c_sb, f_c_c, device } from '../styles';

const DisppearAnimation = keyframes`
    0% { opacity: 1; }
  100% { opacity: 0; }
`;

const AppearAnimation = keyframes`
    0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const Section = styled.section`
	padding: 76px 0 160px;
	position: relative;

	@media ${device.tillTablet} {
		padding: 0 0 120px;
	}
`;

export const Header = styled.div`
	${f_c_c};
	flex-direction: column;
	position: relative;
	margin: 0 auto;

	@media ${device.tillLaptop} {
		max-width: 648px;
		padding: 0 16px;
	}
`;

export const BlueCircle = styled.div`
	width: 24px;
	height: 24px;
	background-color: #7EB3FF;
	border-radius: 50%;
	position: absolute;
    right: 15%;
    top: 70%;

	@media ${device.tillLaptop} {
		right: 15%;
    	top: 52%;
	    width: 16px;
		height: 16px;
    }	

    @media ${device.tillTablet} {
		left: 10%;
    	top: 29%;
	}
`;

export const YellowTriangle = styled.div`
	position: absolute;
	left: 15%;
    top: 50%;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 16px 27.7px 16px;
	border-color: transparent transparent #FED876 transparent;
	transform: rotate(15deg);

	@media ${device.tillLaptop} {
		left: 15%;
   		top: 40%;
    	border-width: 0 12px 20px 12px;
	}

	 @media ${device.tillTablet} {
		right: 10%;
    	left: unset;
    	top: 34%;
	}
`;

export const GreenSquare = styled.div`
	position: absolute;
	left: 34%;
    bottom: 10%;
	width: 24px;
	height: 24px;
	background: #45D09E;
	transform: rotate(-10deg);

	@media ${device.tillLaptop} {
		left: 34%;
    	bottom: 10%;
    	width: 16px;
		height: 16px;
	}
`;

export const Title = styled.h2`
	text-align: center;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 80px;
	line-height: 128px;
	color: ${color.black};
	position: relative;

	@media ${device.tillLaptop} {
		font-size: 64px;
		line-height: 104px;
	}

	@media ${device.tillTablet} {
		font-size: 40px;
		line-height: 64px;
	}
`;

export const ContainerButtonJoin = styled.div`
	width: fit-content;
	margin: 76px auto 0;
	border: 2px solid ${color.purple};
	box-sizing: border-box;
	border-radius: 10px;
	animation: ${props =>
        (props.animateButton) 
            ? DisppearAnimation
            : "none"} .3s ease-in-out;

    @media ${device.tillTablet} {
		margin: 56px auto 0;
	}        
`;

export const ButtonJoin = styled.button`
	background: ${color.purple};
	border-radius: 8px;
	border: 0;
	padding: 14px 120px;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	color: ${color.white};
	transform: translate(-10px, -6px);
	transition: .3s;

	&:hover {
		transform: translate(0, 0);
	}

	&:active {
		transform: translate(0, 0);
		background: #7A6ABB;
	}
`;

export const ContainerButtonAccept = styled.div`
	margin-left: 24px;
	border: 2px solid ${color.purple};
	box-sizing: border-box;
	border-radius: 10px;
`;

export const FormJoin = styled.form`
	display: flex;
	width: fit-content;
	margin: 80px auto 0;

	animation: ${props =>
        (props.showEmail) 
            ? AppearAnimation
            : "none"} .3s ease-in-out;

    @media ${device.tillTablet} {
		margin: 56px auto 0;
	}              
`;

export const InputEmail = styled.input`
	width: 272px;
	border: 2px solid ${color.purple};
	box-sizing: border-box;
	border-radius: 8px;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	color: rgba(152, 135, 218, 0.7);
	background: transparent;

	&::placeholder {
		color: rgba(152, 135, 218, 0.7);
	}

	@media ${device.tillTablet} {
		width: 190px;
	}
`;

export const ButtonAccept = styled.button`
	${f_c_c};
	background: ${color.purple};
	border-radius: 8px;
	border: 0;
	padding: 21px 30px;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	color: ${color.white};
	transform: translate(-10px, -6px);
	transition: .3s;

	&:hover {
		transform: translate(0, 0);
	}

	&:active {
		transform: translate(0, 0);
		background: #7A6ABB;
	}
`;

export const ContainerForm = styled.div`
	& > div {
		position: relative;
		display: flex;
		margin: 80px 0 0 0;
		justify-content: center;
		animation: ${props =>
		    (props.showEmail) 
		        ? AppearAnimation
		        : "none"} .3s ease-in-out;

		    @media ${device.tillTablet} {
			    margin: 56px auto 0;
			}
		}	

	& > div > div {
		position: absolute;
	    z-index: 999;
	    padding: 8px;
	    height: 68px;
        width: 364px;
	    color: ${color.purple} !important;
	    background-color: #f5f3fc;
	    font-family: 'Gilroy',sans-serif;
	    font-style: normal;
	   	font-weight: 600;
		font-size: 20px;
		line-height: 32px;
	    top: -10px;
	    text-align: center;

	    @media ${device.tillTablet} {
			font-size: 16px;
    		line-height: 28px;
		}
	}
	
	& form {
		display: flex;
	}

    & input {
    	width: 272px;
		border: 2px solid ${color.purple};
		box-sizing: border-box;
		border-radius: 8px;
		font-family: 'Gilroy', sans-serif;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 28px;
		text-align: center;
		color: rgba(152, 135, 218, 0.7);
		background: transparent;

		&::placeholder {
			color: rgba(152, 135, 218, 0.7);
		}

		@media ${device.tillTablet} {
			width: 190px;
		}
    }    

    & button {
    	background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 8H15' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 1L15 8L8 15' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    	background-color: ${color.purple};
	    background-repeat: no-repeat;
	    background-position: center;
    	margin-left: 16px;
    	${f_c_c};
		border-radius: 8px;
		border: 0;
		font-size: 0;
		text-align: center;
		color: ${color.white};
		width: 72px;
		height: 56px;
		transform: translate(-10px, -6px);
		transition: .3s;

		&::after {
			content: "";
		    width: 68px;
		    height: 52px;
		    border: 2px solid ${color.purple};
		    border-radius: 8px;
		    position: absolute;
		    transform: translate(8px, 6px);
		    transition: .3s;
		}	

		&:hover {
			transform: translate(0, 0);

			&::after {
			    transform: translate(0, 0);
			}	
		}

		&:active {
			transform: translate(0, 0);
			background-color: #7A6ABB;


			&::after {
			     display: none;
			}	
		}
    }    
`;