import React, { Fragment, useState, useRef, useEffect } from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import EmailForm from '../EmailForm';

import { 
	Section,
	BlueCircle,
	YellowTriangle,
	GreenSquare,
	Header,
	Title,
	ButtonJoin,
	ContainerButtonJoin,
	FormJoin,
	InputEmail,
	ButtonAccept,
	ContainerButtonAccept,
	ContainerForm
 } from './styles';


function Join(props) {

	const [ animateButton, setAnimateButton ] = useState(false);
	const [ showEmail, setShowEmail ] = useState(false);
	const [emailError,setEmailError] = useState('');

	function hideButton(){
		setAnimateButton(true);
		setTimeout(() => {
		    setShowEmail(true);
		  }, 300);
		}

	const url = "https://appcrowder.us21.list-manage.com/subscribe/post?u=3e2fcd666c23af6cef4d9fdef&amp;id=feb0e7bfb4&amp;f_id=002de5e6f0";
	

	return(
		<Section>
			<BlueCircle/>
			<YellowTriangle/>
			<GreenSquare/>
			<Header>
				<Title>Join the Crowd</Title>
			</Header>
			{showEmail ? 
				<React.Fragment> 
					<MailchimpSubscribe url={url} render={props => <EmailForm  {...props}/>}/>
				</React.Fragment>	
				: 
				<ContainerButtonJoin animateButton={animateButton}>
					<ButtonJoin onClick={hideButton}>
						Join
					</ButtonJoin>
				</ContainerButtonJoin>	
				}	
		</Section>
	);
}

export default Join;