import React from 'react';
import Typing from 'react-typing-animation';

import { 
	ContainerTextAnimation,
	AnimatedText
 } from './styles';

function HeroText1(props) {

	const { setAnimationStep } = props;

	return(
    	<ContainerTextAnimation>
			<Typing hideCursor={true} speed={20} onFinishedTyping={() => setAnimationStep()}>
			    <AnimatedText>Let's be real for a sec... </AnimatedText>
			    <Typing.Delay ms={500} />
			    <AnimatedText>We're all tired of swiping...</AnimatedText>
			    <Typing.Delay ms={1000} />
		 	</Typing> 
		</ContainerTextAnimation>	 
	);
}

export default HeroText1;
