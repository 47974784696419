import React from "react";

const Triangle = () => {
    return (      
		<svg width="1162" height="1297" viewBox="0 0 1162 1297" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path opacity="0.1" d="M1152.34 911.659C1169.76 929.078 1161.79 958.821 1138 965.196L-93.3525 1295.14C-117.147 1301.51 -138.92 1279.74 -132.544 1255.94L197.395 24.5922C203.771 0.79795 233.514 -7.17165 250.933 10.247L1152.34 911.659Z" fill="#9887DA"/>
		</svg>
    );
};

export default Triangle;
