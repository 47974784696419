import React from "react";
import styled from "styled-components";
import { device } from '../styles';

const SVG = styled.svg`
	width: 80px;
	height: 80px;
	@media ${device.tillTablet} {
		width: 56px;
		height: 55.15px;
	}
`


const Message = () => {
    return (      	
		<SVG  viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M68.5955 1.77295H25.358C19.7183 1.77295 15.1041 6.38717 15.1041 12.0269V15.5159H54.2162C59.8558 15.5159 64.4701 20.1301 64.4701 25.7698V51.3337H68.5957C74.2354 51.3337 78.8496 46.7195 78.8496 41.0798V12.0269C78.8496 6.38717 74.2352 1.77295 68.5955 1.77295Z" fill="#6C7ED6"/>
			<path d="M54.6893 15.0498H11.4518C5.81209 15.0498 1.19788 19.664 1.19788 25.3037V54.3567C1.19788 59.9964 5.81209 64.6106 11.4518 64.6106H37.4023C38.5148 64.6106 39.3452 65.6459 39.0908 66.7289C38.4027 69.6587 37.3496 72.7571 35.7929 75.9332C35.0938 77.3596 36.6841 78.8098 38.0349 77.9739C41.9407 75.5567 47.4977 71.5111 52.1774 65.792C52.7926 65.0403 53.7177 64.6106 54.6891 64.6106C60.3288 64.6106 64.943 59.9964 64.943 54.3567V25.3039C64.9434 19.6642 60.329 15.0498 54.6893 15.0498Z" fill="#60B8FE"/>
			<path d="M15.3996 44.0639C17.2788 44.0639 18.8023 42.5405 18.8023 40.6613C18.8023 38.7822 17.2788 37.2588 15.3996 37.2588C13.5204 37.2588 11.9969 38.7822 11.9969 40.6613C11.9969 42.5405 13.5204 44.0639 15.3996 44.0639Z" fill="#DFEBFA"/>
			<path d="M26.3892 44.0619C28.2685 44.0619 29.7919 42.5386 29.7919 40.6594C29.7919 38.7802 28.2685 37.2568 26.3892 37.2568C24.51 37.2568 22.9866 38.7802 22.9866 40.6594C22.9866 42.5386 24.51 44.0619 26.3892 44.0619Z" fill="#DFEBFA"/>
			<path d="M37.3962 44.0619C39.2754 44.0619 40.7988 42.5386 40.7988 40.6594C40.7988 38.7802 39.2754 37.2568 37.3962 37.2568C35.517 37.2568 33.9935 38.7802 33.9935 40.6594C33.9935 42.5386 35.517 44.0619 37.3962 44.0619Z" fill="#DFEBFA"/>
			<path d="M48.3684 44.0619C50.2476 44.0619 51.771 42.5386 51.771 40.6594C51.771 38.7802 50.2476 37.2568 48.3684 37.2568C46.4891 37.2568 44.9657 38.7802 44.9657 40.6594C44.9657 42.5386 46.4891 44.0619 48.3684 44.0619Z" fill="#DFEBFA"/>
			<path d="M10.8159 40.6336C10.8159 43.1561 12.8681 45.2082 15.3903 45.2082C17.9128 45.2082 19.9648 43.1561 19.9648 40.6336C19.9648 38.1112 17.9126 36.0591 15.3903 36.0591C12.8681 36.0591 10.8159 38.1112 10.8159 40.6336ZM17.6212 40.6336C17.6212 41.8636 16.6204 42.8644 15.3904 42.8644C14.1604 42.8644 13.1598 41.8636 13.1598 40.6336C13.1598 39.4035 14.1604 38.4029 15.3904 38.4029C16.6204 38.4029 17.6212 39.4035 17.6212 40.6336Z" fill="black"/>
			<path d="M30.9544 40.6336C30.9544 38.1112 28.9023 36.0591 26.3799 36.0591C23.8576 36.0591 21.8055 38.1112 21.8055 40.6336C21.8055 43.1561 23.8577 45.2082 26.3799 45.2082C28.9023 45.2082 30.9544 43.1561 30.9544 40.6336ZM24.1491 40.6336C24.1491 39.4035 25.1498 38.4029 26.3798 38.4029C27.6098 38.4029 28.6105 39.4035 28.6105 40.6336C28.6105 41.8636 27.6098 42.8644 26.3798 42.8644C25.1498 42.8644 24.1491 41.8636 24.1491 40.6336Z" fill="black"/>
			<path d="M41.9441 40.6336C41.9441 38.1112 39.8919 36.0591 37.3695 36.0591C34.8472 36.0591 32.7952 38.1112 32.7952 40.6336C32.7952 43.1561 34.8474 45.2082 37.3695 45.2082C39.8919 45.2082 41.9441 43.1561 41.9441 40.6336ZM35.1388 40.6336C35.1388 39.4035 36.1394 38.4029 37.3694 38.4029C38.5994 38.4029 39.6002 39.4035 39.6002 40.6336C39.6002 41.8636 38.5994 42.8644 37.3694 42.8644C36.1394 42.8644 35.1388 41.8636 35.1388 40.6336Z" fill="black"/>
			<path d="M52.9336 40.6336C52.9336 38.1112 50.8814 36.0591 48.359 36.0591C45.8367 36.0591 43.7847 38.1112 43.7847 40.6336C43.7847 43.1561 45.8369 45.2082 48.359 45.2082C50.8814 45.2082 52.9336 43.1561 52.9336 40.6336ZM46.1283 40.6336C46.1283 39.4035 47.1289 38.4029 48.3589 38.4029C49.5889 38.4029 50.5897 39.4035 50.5897 40.6336C50.5897 41.8636 49.5889 42.8644 48.3589 42.8644C47.1289 42.8644 46.1283 41.8636 46.1283 40.6336Z" fill="black"/>
			<path d="M68.5741 0.570801H25.3366C19.0364 0.570801 13.9106 5.69643 13.9106 11.9966V13.845H11.4258C5.12563 13.8452 0 18.9708 0 25.271V54.3238C0 60.6241 5.12563 65.7495 11.4258 65.7495H23.6414C24.2887 65.7495 24.8133 65.2249 24.8133 64.5777C24.8133 63.9305 24.2887 63.4058 23.6414 63.4058H11.4258C6.41797 63.4058 2.34375 59.3316 2.34375 54.3238V25.271C2.34375 20.2631 6.41797 16.1889 11.4258 16.1889H54.6634C59.6712 16.1889 63.7455 20.2631 63.7455 25.271V54.3238C63.7455 59.3316 59.6712 63.4058 54.6634 63.4058C53.3286 63.4058 52.0825 63.993 51.2445 65.0171C46.6481 70.6342 41.1747 74.6038 37.3925 76.9445C37.0398 77.1622 36.6339 76.7945 36.8192 76.4161C38.3055 73.3836 39.445 70.2035 40.2059 66.9639C40.6322 65.1486 39.2483 63.4058 37.3764 63.4058H29.1103C28.463 63.4058 27.9384 63.9305 27.9384 64.5777C27.9384 65.2249 28.463 65.7495 29.1103 65.7495H37.3764C37.7359 65.7495 38.0056 66.082 37.9242 66.428C37.2036 69.4964 36.1237 72.5099 34.7147 75.3847C33.4997 77.8647 36.2811 80.3886 38.6259 78.9375C42.56 76.5027 48.2575 72.3688 53.0584 66.5014C53.4495 66.0236 54.0345 65.7495 54.6634 65.7495C60.9636 65.7495 66.0892 60.6239 66.0892 54.3238V52.4753H68.5741C74.8744 52.4753 80 47.3497 80 41.0495V29.2575C80 28.6103 79.4755 28.0856 78.8281 28.0856C78.1808 28.0856 77.6562 28.6103 77.6562 29.2575V41.0495C77.6562 46.0574 73.582 50.1316 68.5741 50.1316H66.0892V25.271C66.0892 18.9708 60.9636 13.8452 54.6634 13.8452H16.2544V11.9967C16.2544 6.98893 20.3286 2.91471 25.3366 2.91471H68.5741C73.582 2.91471 77.6562 6.98893 77.6562 11.9967V23.7888C77.6562 24.436 78.1808 24.9606 78.8281 24.9606C79.4755 24.9606 80 24.436 80 23.7888V11.9967C80 5.69643 74.8744 0.570801 68.5741 0.570801Z" fill="black"/>
		</SVG>
    );
};

export default Message;
