import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {RoundWrapper} from './styles';
import LikeLogo from '../images/like';
import CancelLogo from '../images/cancel';

export const LikeButton = ({onClick}) => {
    const [active,setActive] = useState(false);

    const handleClick = ()=> {
        if(active) {
            return;
        }
        setActive(true);
        if(typeof onClick == 'function') {
            onClick();
        }
    }

    const handleTransitionEnd = () => {
        setActive(false);
    }

    return (
        <RoundWrapper onTransitionEnd={handleTransitionEnd} onClick={handleClick} active={active}>
            <LikeLogo/>
        </RoundWrapper>
    );
}

LikeButton.propTypes = {
    onClick: PropTypes.func
}

export const CancelButton = ({onClick}) => {
    const [active,setActive] = useState(false);

    const handleClick = ()=> {
        if(active) {
            return;
        }
        setActive(true);
        if(typeof onClick == 'function') {
            onClick();
        }
    }

    const handleTransitionEnd = () => {
        setActive(false);
    }

    return (
        <RoundWrapper onTransitionEnd={handleTransitionEnd} onClick={handleClick} active={active}>
            <CancelLogo/>
        </RoundWrapper>
    );
}

CancelButton.propTypes = {
    onClick: PropTypes.func
}