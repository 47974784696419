import React, { useState } from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import EmailForm from '../../EmailForm';

import { 
	Container,
	ContainerTitle,
	Title,
	Description,
	ButtonJoin,
	ContainerButtonJoin,
 } from './styles';

function HeroTitle(props) {

	const [ animateButton, setAnimateButton ] = useState(false);
	const [ showEmail, setShowEmail ] = useState(false);

	function hideButton(){
		setAnimateButton(true);
		setTimeout(() => {
		    setShowEmail(true);
		}, 300);
	}

	const url = "https://appcrowder.us21.list-manage.com/subscribe/post?u=3e2fcd666c23af6cef4d9fdef&amp;id=feb0e7bfb4&amp;f_id=002de5e6f0";

	return(
		<Container static={props.static}>
			<ContainerTitle>
				<Title>Find your Crowd</Title>
				<Description>
					You shouldn’t feel alone anymore. Connect with new people who share your interests.
				</Description>
			</ContainerTitle>
			{showEmail ?
					<MailchimpSubscribe url={url} render={props => <EmailForm white style={{margin: '42px auto'}}  {...props}/>}/>				
			: 
				<ContainerButtonJoin animateButton={animateButton}>
					<ButtonJoin onClick={hideButton}>
						Join
					</ButtonJoin>
				</ContainerButtonJoin>	
			}
		</Container>		
	);
}

export default HeroTitle;

