import React, { Fragment, useState } from 'react';

import Logo from '../images/logo';

import { 
	ContainerHeader,
	ContainerLogo,
	ContainerNav,
	ContainerMobileNav,
	HeaderBurgerButton
 } from './styles';

import BurgerMenu from "../images/burger_menu";
import WhiteCross from "../images/white_cross";

function Header(props) {

	const { isMobile } = props;
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	console.log(isMenuOpen)

	function toggleMenu() {
		setIsMenuOpen(!isMenuOpen)
	}

	return(
		<ContainerHeader static={props.isResizing}>
			<ContainerLogo>
				<Logo />
			</ContainerLogo>
			{isMobile ? 
				<Fragment>
				<HeaderBurgerButton onClick={toggleMenu} >
					{isMenuOpen ? <WhiteCross /> : <BurgerMenu />}
				</HeaderBurgerButton>
				<ContainerMobileNav isMenuOpen={isMenuOpen}>
					<ul>
						<li><a href="#home" onClick={toggleMenu}>Home</a></li>
						<li><a href="#about" onClick={toggleMenu}>About</a></li>
						<li><a href="#how" onClick={toggleMenu}>How it works</a></li>
					</ul>
				</ContainerMobileNav> 
				</Fragment>
				:
				<ContainerNav>
					<ul>
						<li><a href="#home">Home</a></li>
						<li><a href="#about">About</a></li>
						<li><a href="#how">How it works</a></li>
					</ul>
				</ContainerNav>
			}
		</ContainerHeader>
	);
}

export default Header;