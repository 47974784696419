import styled, { keyframes } from "styled-components";
import { color, f_c_sb, f_c_c, device } from '../styles';

export const Section = styled.section`
	background-color: ${color.white};
	padding: 76px 0 60px;
	position: relative;
	overflow: hidden;

	@media ${device.tillTablet} {
		padding: 40px 0 20px;
	}
	@media (min-width: 800px) and (max-width: 1024px){
		padding: 0 0 60px;
	}
`;

export const Anchor = styled.div`
	position: absolute;
	top: -50px;

	@media ${device.tillTablet} {
		top: -100px;
	}
`;

export const PurpleSquare = styled.div`
	width: 24px;
	height: 24px;
	position: absolute;
    top: -3%;
    left: 5%;
	background-color: #9887DA;
	transform: rotate(-5deg);

	@media ${device.tillLaptop} {
	    width: 16px;
		height: 16px;
		top: -1%;
	}
`;

export const Header = styled.div`
	${f_c_c};
	flex-direction: column;
	position: relative;
	margin: 0 auto;

	@media ${device.tillLaptop} {
		max-width: 648px;
		padding: 0 16px;
	}
`;

export const BlueCircle = styled.div`
	width: 24px;
	height: 24px;
	background-color: #7EB3FF;
	border-radius: 50%;
	position: absolute;
    right: 96px;
    top: 60%;

	@media ${device.tillLaptop} {
		right: -4%;
    	top: 83%;
	    width: 16px;
		height: 16px;
    }	

    @media ${device.tillTablet} {
		right: 0%;
    	top: 14%;
	}
`;

export const Title = styled.h2`
	text-align: center;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 80px;
	line-height: 128px;
	color: ${color.black};
	position: relative;

	@media ${device.tillLaptop} {
		font-size: 64px;
		line-height: 104px;
	}

	@media ${device.tillTablet} {
		font-size: 40px;
		line-height: 64px;
	}

	&::after {
		content: '';
		position: absolute;
		width: 96px;
		height: 4px;
		background: #FFD600;
		bottom: -16px;
	    left: 50%;
	    margin-right: -50%;
	    transform: translate(-50%, 0);
	}
`;

export const Description = styled.p`
	text-align: center;
	margin-top: 48px;
	max-width: 944px;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	color: ${color.black};
	opacity: 0.6;

	media ${device.tillTablet} {
		font-size: 16px;
		line-height: 28px;
	}
`;

export const Content = styled.div`
	margin-top: 80px;
	display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;

	& > div {
		${f_c_c};
		flex-direction: column;
		position: relative;

		&:first-child {
			background: #81CBF4;
			flex-direction: row;
	    	align-items: flex-end;

			@media ${device.tillTablet} {
				order: 1;
			}
		}

		&:nth-child(2) {
			@media ${device.tillTablet} {
				order: 2;
			}
		}

		&:nth-child(3) {
			@media ${device.tillTablet} {
				order: 4;
			}
		}

		&:nth-child(4) {
			background: #FBDE9D;
			flex-direction: row;
		    align-items: flex-end;

		    @media ${device.tillTablet} {
				order: 3;
			}
		}

		&:nth-child(5) {
			background: #8DEBBC;
			flex-direction: row;
		    align-items: flex-end;

		    @media ${device.tillTablet} {
				order: 5;
			}
		}

		&:last-child {
		    @media ${device.tillTablet} {
				order: 6;
			}
		}
	}

	& > div > p {
		

	}	

	@media ${device.tillTablet} {
    	grid-template-columns: 1fr;
	}

`;

export const ContainerTriangle = styled.div`
	position: absolute;
`;

export const YellowTriangle = styled.div`
	position: absolute;
	left: 104px;
    top: 104px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 16px 27.7px 16px;
	border-color: transparent transparent #FED876 transparent;
	transform: rotate(-10deg);

	@media ${device.tillLaptop} {
		left: 40px;
    	top: 40px;
    	border-width: 0 12px 20px 12px;
	}
	@media ${device.tillTablet} {
		left: 20px;
		top: 40px;
	}
`;

export const GreenSquare = styled.div`
	position: absolute;
	right: 40px;
    bottom: 42px;
	width: 24px;
	height: 24px;
	background: #45D09E;
	transform: rotate(15deg);

	@media ${device.tillLaptop} {
		right: 35px;
    	bottom: 13px;
    	width: 16px;
		height: 16px;
	}
	@media ${device.tillTablet} {
		right: 20px;
	}
`;

export const PurpleCircle = styled.div`
	position: absolute;
	right: 64px;
    top: 44px;
	width: 24px;
	height: 24px;
	background: #9887DA;
	border-radius: 50%;

	@media ${device.tillLaptop} {
		right: 28px;
    	top: 44px;
    	width: 16px;
		height: 16px;
	}
`;

export const BlueTriangle = styled.div`
	position: absolute;
	left: 140px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 16px 27.7px 16px;
	border-color: transparent transparent #7EB3FF transparent;
	transform: rotate(5deg);

	@media ${device.tillLaptop} {
		left: 0;
    	bottom: 85px;
    	border-width: 0 12px 20px 12px;
	}

	@media ${device.tillTablet} {
		bottom: 5px;
		left: 15px;
	}
`;

export const YellowSquare = styled.div`
	position: absolute;
	left: 33px;
    top: 28px;
	width: 24px;
	height: 24px;
	background: #FED876;
	transform: rotate(20deg);

	@media ${device.tillLaptop} {
		left: 20px;
    	top: 26px;
    	width: 16px;
		height: 16px;
	}

	@media ${device.tillTablet} {
		left: 22px;
    	top: 50px;
	}
`;

export const PurpleTriangle = styled.div`
	position: absolute;
	width: 0;
	height: 0;
	right: 48px;
	border-style: solid;
	border-width: 0 16px 27.7px 16px;
	border-color: transparent transparent #9887DA transparent;
	transform: rotate(-45deg);

	@media ${device.tillLaptop} {
		right: 26px;
		bottom: 20px;
		border-width: 0 12px 20px 12px;
	}

	@media ${device.tillTablet} {
		bottom: 40px;
	}
`;

export const GreenCircle = styled.div`
	position: absolute;
	bottom: 0;
    left: 47%;
    margin-right: -50%;
    transform: translate(-50%, 0);
	width: 24px;
	height: 24px;
	background: #45D09E;
	border-radius: 50%;

	@media ${device.tillLaptop} {
	    width: 16px;
		height: 16px;
	}
	@media ${device.tillTablet} {
		left: 30%;
	}
`;

export const Text = styled.p`
	margin-top: 40px;
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	text-align: center;
	color: ${color.black};
	opacity: 0.6;
	max-width: 320px;

	@media (min-width: 768px) {
		max-width: 256px;
		padding: 0 16px;
	}
	@media ${device.tillTablet} {
		width: 100%;
		line-height: 28px;
		font-size: 19px;
	}
	@media ${device.tillMobileS} {
		width: 288px;
		font-size: 16px;
	}
`;


export const ContentBlock = styled.div`
	height: 640px;
	@media ${device.tillLaptop} {
		width: 100%;
		height: 340px;
		margin: 0 auto;
		height: auto;
	}
	@media ${device.tillTablet} {
		padding-top: 40px;
		padding-bottom: 56px;
		height: auto;
	}
`;

export const ImageBlock = styled.div`
	height: 640px;
	@media ${device.tillLaptop} {
		height: 340px;

		& > img {
			height: 300px;
		}
	}
`
