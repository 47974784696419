import styled from "styled-components";

// Color palette
export const color = {
  black: "#14263C",
  white: "#FFFFFF",
  purple: "#9887DA",
  grey: "#A1A8B1",
  lightGrey: "#FAFAFA",
  blue: "#8EA7FF",
};

// Based on: https://jsramblings.com/2018/02/04/styled-components-media-queries.html
export const size = {
  mobile: "320",
  tablet: "767",
  laptop: "1024",
};

export const device = {
  mobile: `(min-width: ${size.mobileS}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  laptop: `(min-width: ${size.laptop}px)`,
  tillTablet: `(max-width: ${size.tablet}px)`,
  tillLaptop: `(max-width: ${size.laptop}px)`,
  tillMobileS: `(max-width: ${size.mobile}px)`,
};


export const transition = `
  transition: all 0.3s ease;
`;

export const f_c_sb = `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const f_c_c = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const f_sb = `
  display: flex;
  justify-content: space-between;
`;


export const LandingWrapper = styled.div`
  position: relative;
  height: 100%;
  padding: 0 64px;
  margin: 0 auto;
  font-family: 'Metropolis', sans-serif;
  box-sizing: border-box;

  @media ${device.tillLaptop} {
    padding: 0 16px;
  } 
`;

// export const LandingMainContainer = styled.div`
//   position: relative;
//   width: 100%;
//   height: 100%;
//   overflow-y: scroll;
// `;

export const LandingBody = styled.div`
  position: relative;
  background-color: ${color.white};
  width: 100%;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;
