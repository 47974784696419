import styled, { keyframes } from "styled-components";
import { color, device } from '../../styles';

export const ContainerTextAnimation = styled.div`
	margin-top: -300px;
`;

export const AnimatedText = styled.p`
	font-family: 'Gilroy', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 76px;
	line-height: 128px;
	text-align: center;
	color: #FFFFFF;

	@media ${device.tillLaptop} {
		font-size: 56px;
		line-height: 96px;
	}
`;


