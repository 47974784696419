import styled,{keyframes} from 'styled-components';
import { 
    color,
    f_c_c, 
    device 
} from '../styles';

const mainColor = (white) => {
    return white ? color.white : color.purple
}

const secondColor = (white) => {
    return white ? color.purple : color.white
}

const DisppearAnimation = keyframes`
    0% { opacity: 1; }
  100% { opacity: 0; }
`;

const AppearAnimation = keyframes`
    0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const ContainerForm = styled.div`
    ${({error,white}) => `
        & > div {
            position: relative;
            display: flex;
            margin: 78px auto 2px;
            justify-content: center;
            animation: ${props =>
                (props.showEmail) 
                    ? AppearAnimation
                    : "none"} .3s ease-in-out;

                @media ${device.tillTablet} {
                    margin: 58px auto 2px;
                }
            }	

        & > div > div {
            position: absolute;
            z-index: 999;
            padding: 8px;
            height: 68px;
            width: 364px;
            font-family: 'Gilroy',sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            color: ${mainColor(white)};
            line-height: 32px;
            top: -10px;
            text-align: center;

            @media ${device.tillTablet} {
                font-size: 16px;
                line-height: 28px;
            }
        }
        
        & form {
            display: flex;
        }
    `}    
`;

export const ErrorText = styled.div`
    position: absolute;
    top: 100%;
    color: #FF5775;
    left: 0;
    padding-left: 16px;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    ${({white}) => {
        if(white) {
            return `
                width: 100%;
                color: #ffffff;
                background: #FF5775;
                border-radius: 0px 0px 8px 8px;
                &::before {
                    content: '';
                    position: absolute;
                    border-width: 0 8px 11.7px 0px;
                    border-color: transparent transparent #FF5775 transparent;
                    border-style: solid;
                    top: -9px;
                    left: 0;
                }

                &::after {
                    content: '';
                    position: absolute;
                    border-width: 0 0px 11.7px 8px;
                    border-color: transparent transparent #FF5775 transparent;
                    border-style: solid;
                    top: -9px;
                    right: 0;
                }
            `;
        }
    }}
`;


export const InputWrapper = styled.div`
    position: relative;
`;

export const Input = styled.input.attrs({
    type: 'text',
    placeholder: 'Your email',
})`
    width: 272px;
    height: 100%;
    border: 2px solid ${({error,white}) => error ? '#FF5775' : mainColor(white)};
    box-sizing: border-box;
    border-radius: 8px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    padding-left: 16px;
    color: ${({white}) => white ? 'rgba(250, 250, 250, 0.7)' : 'rgba(152, 135, 218, 0.7)'};
    background: transparent;

    &::placeholder {
        color: ${({white}) => white ? 'rgba(250, 250, 250, 0.7)' : 'rgba(152, 135, 218, 0.7)'};
    }

    @media ${device.tillTablet} {
        width: 190px;
    }
`;


export const SubmitButton = styled.button`
    background-image: ${({error,white}) => {
        if(error) {
            return white ?`url(/images/red-cross.svg)` : `url(/images/cross.svg)`;
        }else {
            return white ? `
            url("data:image/svg+xml,%0A%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 8H15' stroke='%239887DA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 1L15 8L8 15' stroke='%239887DA' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
        ` : `
            url("data:image/svg+xml,%0A%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 8H15' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 1L15 8L8 15' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
        `
        }
    }};
    background-color: ${({error,white}) => error ? (white ? color.white : '#FF5775') : mainColor(white)};
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 16px;
    ${f_c_c};
    border-radius: 8px;
    border: 0;
    font-size: 0;
    text-align: center;
    color: ${({white}) => secondColor(white)};
    width: 72px;
    height: 56px;
    transform: translate(-10px, -6px);
    transition: .3s;

    &::after {
        content: "";
        width: 68px;
        height: 52px;
        border: 2px solid ${({error,white}) => error ? (white ? color.white : '#FF5775') : mainColor(white)};
        border-radius: 8px;
        position: absolute;
        bottom: -8px;
        left: 6px;
        transition: .3s;
    }	

    &:hover {
        transform: translate(0, 0);

        &::after {
            bottom: 0;
            left: 0;
            transform: translate(0, 0);
        }	
    }

    &:active {
        transform: translate(0, 0);
        background-color: #7A6ABB;


        &::after {
            display: none;
        }	
    }

    
`;