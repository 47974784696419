import React from 'react';

const Cancel = () => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48 16L16 48" stroke="white" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16 16L48 48" stroke="white" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Cancel;