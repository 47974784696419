import React, { Fragment, useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components'
import ReactSwing from 'react-swing';

import { 
	AniamatedCards,
	CardFirst,
	CardSecond,
	ContainerSwipe
 } from './styles';

import {LikeButton,CancelButton} from '../../buttons';

function HeroCards(props) {

	const { setAnimationStep } = props;

	//SWING CARDS 
	const [stack, setStack] = useState(null);
  	const [cardCount, setCardCount] = useState(2);
  	const stackEl = useRef();
  	const [card1, setCard1] = useState(true);
	const [card2, setCard2] = useState(true);
	const [activeCard,setActiveCard] = useState(1);  
  	const [card1Button, setCard1Button] = useState('');
  	const [card2Button, setCard2Button] = useState('');

  	const move = keyframes`
	  0% {
	    transform: translateX(-2px)
	  }

	  23% {
	    transform: translateX(-10px)
	  }

	  26% {
	    transform: translateX(-10px)
	  }

	  50% {
	    transform: translateX(-2px)
	  }

	  74% {
	    transform: translateX(6px)
	  }

	  77% {
	    transform: translateX(6px)
	  } 

	  100% {
	    transform: translateX(-2px)
	  }
	`;

	const Path = styled.path`
	  animation: ${move} 3s;
	  animation-iteration-count: infinite;
	  transform: translateX(2px)
	`;

  	// throwOut Method
  	const throwCard = () => {
    // ReactSwing Card Directions
    	console.log('ReactSwing.DIRECTION', ReactSwing.DIRECTION);

	    console.log('stack', stack);
	    console.log('stack.getConfig', stack.getConfig());
	    console.log('stackEl', stackEl);

	    // ReactSwing Component Childrens
	    const targetEl = stack.childElements[1];
	    console.log('targetEl', targetEl);

	    if (targetEl && targetEl.current) {
	      // stack.getCard
	      const card = stack.getCard(targetEl.current);

	      console.log('card', card);

	      // throwOut method call
	      card.throwOut(100, 200, ReactSwing.DIRECTION.RIGHT);
	    }
  	};

  	const addCard = () => {
	   setCardCount(cardCount + 1);
	};

	const handleOnLike = () => {
		if(activeCard == 1) {
			setActiveCard(2);
			setCard1Button('right');
		} else {
			setCard2Button('right');
		}
	}

	const handleOnDislike = () => {
		if(activeCard == 1) {
			setActiveCard(2);
			setCard1Button('left');
		} else {
			setCard2Button('left');
		}
	}

	return(
		<Fragment>
			<AniamatedCards>
				<CancelButton onClick={handleOnDislike}/>
				<div>
					<ReactSwing
			          className="stack"
			          setStack={(stack) => setStack(stack)}
			          ref={stackEl}
			          throwout={(e) => console.log('throwout', e)}
			          dragend={(e) => console.log("End???")}
			        >
			         	{card1 ? <CardSecond move={card2Button} onAnimationEnd={(e)=>{
							 setCard1(!card1)
							 setAnimationStep();
						 }} throwout={() => setCard1(!card1)} throwoutend={() => setAnimationStep()}>
					      	<div></div>
					      	<h3>Michelle, 25</h3>
					      	<p>blogger</p>
					    </CardSecond> : <div/> }
			         	{card2 ? <CardFirst onAnimationEnd={(e)=>{
							 setCard2(!card2)
						 }} move={card1Button}  throwout={() => setCard2(!card2)}>
					      	<div></div>
					      	<h3>Andy, 32</h3>
					      	<p>driver</p>
					    </CardFirst> : <div />}
			        </ReactSwing>
			    </div>
				<LikeButton onClick={handleOnLike} />    
	        </AniamatedCards> 
	        <ContainerSwipe> 	
				<svg width="90" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M71.4224 6.95435H50.5522C49.5922 6.95435 48.813 7.73355 48.813 8.69358C48.813 9.65361 49.5922 10.4328 50.5522 10.4328H71.4224C72.3824 10.4328 73.1617 9.65361 73.1617 8.69358C73.1617 7.73355 72.3824 6.95435 71.4224 6.95435Z" fill="#C1B7E9"/>
					<path d="M72.6543 7.46574L65.6975 0.508955C65.0192 -0.169326 63.9165 -0.169326 63.2382 0.508955C62.5599 1.18724 62.5599 2.28993 63.2382 2.96821L68.9636 8.69366L63.2348 14.4225C62.5565 15.1008 62.5565 16.2035 63.2348 16.8818C63.5757 17.2191 64.0208 17.3897 64.4661 17.3897C64.9114 17.3897 65.3565 17.2193 65.6975 16.8818L72.6543 9.925C73.3325 9.24655 73.3325 8.14402 72.6543 7.46574Z" fill="#C1B7E9"/>
					<path d="M26.2042 6.95435H5.33396C4.37394 6.95435 3.59473 7.73355 3.59473 8.69358C3.59473 9.65361 4.37394 10.4328 5.33396 10.4328H26.2042C27.1642 10.4328 27.9434 9.65361 27.9434 8.69358C27.9434 7.73355 27.1642 6.95435 26.2042 6.95435Z" fill="#C1B7E9"/>
					<path d="M7.7929 8.69341L13.5183 2.96797C14.1966 2.28969 14.1966 1.18699 13.5183 0.508711C12.8401 -0.16957 11.7374 -0.16957 11.0591 0.508711L4.10246 7.46566C3.42418 8.14394 3.42418 9.24664 4.10246 9.92492L11.0592 16.8817C11.4002 17.2191 11.8453 17.3896 12.2906 17.3896C12.7359 17.3896 13.181 17.2192 13.5185 16.8783C14.1968 16.2 14.1968 15.0973 13.5185 14.419L7.7929 8.69341Z" fill="#C1B7E9"/>
					<Path d="M69.6839 34.7811C68.2543 34.7811 66.922 35.216 65.8159 35.9569C64.8629 33.2508 62.2784 31.3028 59.2488 31.3028C57.8192 31.3028 56.4869 31.7377 55.3808 32.4786C54.4278 29.7724 51.8433 27.8245 48.8137 27.8245C47.5477 27.8245 46.3581 28.1654 45.3354 28.7602V17.3894C45.3354 13.5527 42.2153 10.4326 38.3786 10.4326C34.5419 10.4326 31.4218 13.5527 31.4218 17.3894V46.9555L25.3765 42.4232C21.7938 39.7344 16.6945 40.0927 13.5188 43.265C11.484 45.2999 11.484 48.6078 13.5188 50.6426L38.2883 75.4156C41.2449 78.3721 45.1789 80 49.3599 80H57.5097C68.0562 80 76.6407 71.4189 76.6407 60.869V41.7379C76.6407 37.9012 73.5206 34.7811 69.6839 34.7811ZM73.1624 60.8689C73.1624 69.4987 66.1396 76.5215 57.5097 76.5215H49.3599C46.1042 76.5215 43.0467 75.2554 40.7476 72.9561L15.9781 48.1834C15.2998 47.5051 15.2998 46.4024 15.9781 45.7241C17.0563 44.6493 18.4963 44.0962 19.9434 44.0962C21.1191 44.0962 22.3017 44.4615 23.2931 45.2058L32.1177 51.8251C32.6465 52.2216 33.3561 52.2808 33.9405 51.9886C34.5282 51.693 34.9005 51.0912 34.9005 50.4338V17.3894C34.9005 15.4728 36.4587 13.9111 38.3788 13.9111C40.2989 13.9111 41.8571 15.4729 41.8571 17.3894V43.4771C41.8571 44.4372 42.6363 45.2164 43.5963 45.2164C44.5564 45.2164 45.3356 44.4372 45.3356 43.4771V34.7813C45.3356 32.8646 46.8938 31.303 48.8139 31.303C50.7339 31.303 52.2922 32.8648 52.2922 34.7813V43.4771C52.2922 44.4372 53.0714 45.2164 54.0314 45.2164C54.9915 45.2164 55.7707 44.4372 55.7707 43.4771V38.2596C55.7707 36.343 57.3289 34.7813 59.249 34.7813C61.169 34.7813 62.7273 36.3431 62.7273 38.2596V43.4771C62.7273 44.4372 63.5065 45.2164 64.4665 45.2164C65.4266 45.2164 66.2058 44.4372 66.2058 43.4771V41.7379C66.2058 39.8213 67.764 38.2596 69.6841 38.2596C71.6041 38.2596 73.1624 39.8214 73.1624 41.7379V60.8689Z" fill="#C1B7E9"/>
				</svg>
	        </ContainerSwipe> 
        </Fragment>      
	);
}

export default HeroCards;
