const initialState = {
  showHero: false,
};


const general = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_HERO_CONTENT':
      const showHero = action.payload || true;
      return {
        ...state,
        showHero
      };

        
    default:
      return state
  }
}

export default general