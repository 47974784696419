import React from 'react';
import Typing from 'react-typing-animation';

import { 
	ContainerTextAnimation,
	AnimatedText
 } from './styles';

function HeroText2(props) {

	const { setAnimationStep } = props;

	return(
    	<ContainerTextAnimation>
			<Typing hideCursor={true} speed={20} onFinishedTyping={() => setAnimationStep()}>
				<Typing.Delay ms={700} />
			   	<AnimatedText>Our goal is to introduce you to new interesting people around you</AnimatedText>
			   	<Typing.Delay ms={500} />
			   	<AnimatedText>You shouldn’t feel alone anymore...</AnimatedText>
			   	<Typing.Delay ms={500} />
			   	<AnimatedText>We’re here to help!</AnimatedText>
			   	<Typing.Delay ms={1200} />
		 	</Typing> 
		</ContainerTextAnimation>	 
	);
}

export default HeroText2;
